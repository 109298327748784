body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pin-input:focus-visible {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 768px) {
 


  .slick-prev, .slick-next{
    width: auto !important;
  }
  .css-1il7x0z-MuiFormGroup-root{
    padding-left: 0 !important;
  }
  .css-1fosles-MuiTypography-root {
    font-size: 0;
  }
  .css-1upm983-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
  }
  
  
  
  }