* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.TextField-without-border-radius fieldset {
  border-radius: 35.5689px;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 35px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ff414c !important;
  font-weight: 700 !important;
}

.carousal-style {
  border: 1px solid red !important;
}
.css-8eilq3-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: white !important;
}
.css-8eilq3-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover {
  background-color: white !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 10px !important;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  border-radius: 15px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  /* width: 20% !important; */
  max-width: 300px !important;
  border-radius: 30px !important;
}
.MuiMobileStepper-dotActive {
  background-color: #ff414c !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #ff414c !important;
}
.css-1tjpmqh-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #ff414c !important;
}
.basic-multi-select {
  border-radius: 35px !important;
}
.select__control .css-13cymwt-control {
  border-radius: 35px !important;
  width: 100% !important;
  border: none !important;
  background-color: transparent !important;
  min-height: 55px !important;
}
.select__control--is-Focused {
  border-radius: 35px !important;
  width: 100% !important;
  border: none !important;
  border-color: white !important;
}
.css-t3ipsp-control {
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100% !important;
  background-color: transparent !important;
  min-height: 55px !important;
}
.css-t3ipsp-control:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

.css-1nmdiq5-menu {
  z-index: 2 !important;
}
.css-3w2yfm-ValueContainer {
  padding: 5px 16px !important;
}
.css-1p3m7a8-multiValue {
  border-radius: 12px !important;
  background-color: transparent !important;
  color: #ff414c !important;
  border: 1px solid #ff414c !important;
}
.css-wsp0cs-MultiValueGeneric {
  color: #ff414c !important;
}
.css-12a83d4-MultiValueRemove:hover {
  background-color: transparent !important;
}

/* the slides
.slick-slide {
  margin: 0 27px !important;
} */

/* the parent */
.slick-list {
  margin: 0 -5px !important;
}
.slick-slide {
  height: auto !important;
}
.slick-slide > div {
  padding: 0 5px !important;
}
.slick-track {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
}

.slick-prev:before {
  /* content: "<" !important; */
  color: black !important;
  font-size: 30px !important;
}

.slick-next:before {
  /* content: ">" !important; */
  color: black !important;
  font-size: 30px !important;
}

.css-1aquho2-MuiTabs-indicator {
  bottom: 10px !important;
}

.css-13cymwt-control {
  border: none !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding-bottom: 2em !important;
}

.css-qogg4z-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ff414c !important;
  font-weight: 700 !important;
}

.agency-carousal {
  height: 22.5em !important;
}
.agency-tab-carousal {
  height: 25.5rem !important;
}

@media screen and (max-width: 1300px) {
  .agency-carousal {
    height: 10.5em !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1440px) {
  .agency-carousal {
    height: 13.5em !important;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1800px) {
  .agency-carousal {
    height: 15em !important;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2200px) {
  .agency-carousal {
    height: 17.5em !important;
  }
}

.PhoneInput input {
  /* Add your custom styles for the input element here */
  border: none;
  background-color: transparent;
  height: 100%;
  border-radius: 35px;
  width: 90%;
}

.PhoneInput input:focus-visible {
  /* Add your custom styles for the input element here */
  outline: none;
}

.input:-webkit-autofill {
  background-color: #f7f7f7 !important;
}
